/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon } from '@heroicons/react/solid'
import { CheckIcon, ChevronDownIcon, AdjustmentsIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { Menu, Listbox, Transition, Dialog } from '@headlessui/react'
import { MailIcon, PhoneIcon, EyeIcon } from '@heroicons/react/outline'
import { PlusIcon } from '@heroicons/react/solid'
import { BANCOS } from '../Constantes'





import { Fragment, useEffect, useRef, useState } from 'react';
import HttpService from '../services/HttpService';

const statusStyles = {
    1: 'bg-green-100 text-green-800',
    '-1': 'bg-yellow-100 text-yellow-800',
    4: 'bg-gray-100 text-gray-800',
    2: 'bg-red-100 text-red-800',
}

const statusNames = {
    1: 'Pagado',
    '-1': 'Pendiente',
    4: 'Pospuesto',
    2: 'Rechazado',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const tipoCodi = [
    { id: '0', nombre: 'Todo' },
    { id: 'QR', nombre: 'QR' },
    { id: 'MESSAGE', nombre: 'MESSAGE' }
]

const estados = [
    { id: '0', nombre: 'Todo' },
    { id: '-1', nombre: 'Pendiente' },
    { id: '1', nombre: 'Pagado' },
    { id: '4', nombre: 'Pospuesto' },
    { id: '2', nombre: 'Rechazado' }
]



export default function Cuentas(props) {

    const [open, setOpen] = useState(false)

    const [id, setId] = useState("")
    const [correo, setCorreo] = useState("")
    const [nombre, setNombre] = useState("")
    const [apellidos, setApellidos] = useState("")

    const [usuarios, setUsuarios] = useState([]);
    const [rolSelected, setRolSelected] = useState({})

    const [roles, setRoles] = useState([{"nombre":"Usuario","id":"user"},{"nombre":"Administrador","id":"admin"}]);


    const [modalTitle, setModalTitle] = useState();
    const [banco, setBanco] = useState();
    const [claveBanco, setClaveBanco] = useState();
    const [validateRolError, setValidateRolError] = useState();
    const [validateNombreError, setValidateNombreError] = useState();
    const [validateCorreoError, setValidateCorreoError] = useState();
    const [validateApellidosError, setValidateApellidosError] = useState();

    const validForm = useRef(true);

    const creating = useRef(true);


    useEffect(() => {
        props.handleTitulo("Cuentas clabe para recepcion de fondos");
        getUsuarios();
    }, []);

    const getUsuarios = () => {
        HttpService.getAxiosClient().get('https://gotpaymx.com/api/codi/usuarios')
            .then(res => {
                console.log(res)
                setUsuarios(res.data)
            })
    }

    const handleRolSelected = (rol) => {
        setRolSelected(rol);
        setValidateRolError(null)
    }

    const handleCorreo = (correo) => {
        setNombre(nombre);
        setValidateCorreoError(null)
    }


    const handleNombre = (nombre) => {
        setNombre(nombre);
        setValidateNombreError(null)
    }

    const handleApellidos = (apellidos) => {
        setApellidos(apellidos);
        setValidateApellidosError(null)
    }

    const guardarClabe = () => {
        validateForm();

        if (validForm.current) {
            if (creating.current) {
                var url = 'https://gotpaymx.com/api/codi/cuentas';
                HttpService.getAxiosClient().post(url, {
                   
                })
                    .then(res => {
                        console.log(res)
                        getUsuarios()
                        setOpen(false)
                    })
            } else {
                var url = `https://gotpaymx.com/api/codi/cuentas/${id}`;
                HttpService.getAxiosClient().post(url, {
                   
                })
                    .then(res => {
                        console.log(res)
                        getUsuarios()
                        setOpen(false)
                    })
            }
        }
    }

    const validateForm = () => {
        validForm.current = true;
       

    }



    const editar = (usuario) => {
        setOpen(true);
        creating.current = false;
        setId(usuario.id);
        setCorreo(usuario.correo);
        setNombre(usuario.nombre);
        setApellidos(usuario.apellidos);

    }


    return (
        <div className="px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <p className="mt-2 text-sm text-gray-700">
                        Usuarios
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        type="button"
                        onClick={() => { creating.current = true; setOpen(true); setModalTitle("Agregar cuenta clabe"); setCorreo(""); setNombre(""); setApellidos("") }}
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Agregar usuario
                    </button>
                </div>
            </div>



            <ul role="list" className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 ">
                {usuarios.map((usuario) => (
                    <li key={usuario.id} className="col-span-1 divide-y divide-gray-300 rounded-lg shadow-xl mt-8 bg-indigo-200">

                        <div className="px-6 py-4 flex flex-1 items-center justify-between">
                            <p className="text-md font-medium text-gray-900 uppercase">{usuario.username}</p>
                            <Menu as="div" className="flex-shrink-0 pr-2 relative inline-block">
                                <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                    <span className="sr-only">Open options</span>
                                    <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#" onClick={(e) => {e.preventDefault(); editar(usuario)}}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Editar
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </div>
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Eliminar
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>

                        <div className="rounded-b-lg bg-white ">
                            <div className="px-6 py-4" >
                                <p className="text-sm"><span className="text-gray-900">Nombre:</span><span className="text-gray-500 ml-3">{usuario.nombre}</span></p>
                                <p className="text-sm mt-1"><span className="text-gray-900">Apellidos:</span><span className="text-gray-500 ml-3">{usuario.apellidos}</span></p>
                                <p className="text-sm mt-1"><span className="text-gray-900">Rol:</span><span className="text-gray-500 ml-3">{usuario.rol}</span></p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <Dialog.Title as="h3" className="text-lg text-center font-medium leading-6 text-gray-900 ">
                                            {modalTitle}
                                        </Dialog.Title>
                                        <div className="mt-6 ">
                                            <div className="">
                                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                                    Correo
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="nombreCuenta"
                                                        id="nombreCuenta"
                                                        value={correo}
                                                        onChange={(e) => handleCorreo(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs font-mono"
                                                    />
                                                    <span className="inline-flex text-xs text-red-700">{validateCorreoError}</span>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                                    Nombre
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="clabe"
                                                        id="clabe"
                                                        value={nombre}
                                                        onChange={(e) => handleNombre(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs font-mono"
                                                    />
                                                    <span className="inline-flex text-xs text-red-700">{validateNombreError}</span>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                                    Apellidos
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="clabe"
                                                        id="clabe"
                                                        value={apellidos}
                                                        onChange={(e) => handleApellidos(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs font-mono"
                                                    />
                                                    <span className="inline-flex text-xs text-red-700">{validateApellidosError}</span>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <Listbox value={rolSelected} onChange={handleRolSelected}>
                                                    {({ open }) => (
                                                        <>
                                                            <Listbox.Label className="block text-sm font-medium text-gray-700">Cuenta</Listbox.Label>
                                                            <div className="relative mt-1 w-56">
                                                                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                                    <span className="inline-flex w-full truncate">
                                                                        <span className="truncate">{rolSelected.nombre}</span>
                                                                    </span>
                                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                    </span>
                                                                </Listbox.Button>

                                                                <Transition
                                                                    show={open}
                                                                    as={Fragment}
                                                                    leave="transition ease-in duration-100"
                                                                    leaveFrom="opacity-100"
                                                                    leaveTo="opacity-0"
                                                                >
                                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                        {roles.map((rol) => (
                                                                            <Listbox.Option
                                                                                key={rol.nombre}
                                                                                className={({ active }) =>
                                                                                    classNames(
                                                                                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                    )
                                                                                }
                                                                                value={rol}
                                                                            >
                                                                                {({ cuentaSelected, active }) => (
                                                                                    <>
                                                                                        <div className="flex">
                                                                                            <span className={classNames(cuentaSelected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                                                                {rol.nombre}
                                                                                            </span>
                                                                                        </div>

                                                                                        {cuentaSelected ? (
                                                                                            <span
                                                                                                className={classNames(
                                                                                                    active ? 'text-white' : 'text-indigo-600',
                                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                                )}
                                                                                            >
                                                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            </span>
                                                                                        ) : null}
                                                                                    </>
                                                                                )}
                                                                            </Listbox.Option>
                                                                        ))}
                                                                    </Listbox.Options>
                                                                </Transition>
                                                            </div>
                                                        </>
                                                    )}
                                                </Listbox>
                                                <span className="inline-flex text-xs text-red-700">{validateRolError}</span>
                                            </div>
                                            

                                        </div>
                                    </div>
                                    <div className="mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                            onClick={() => guardarClabe()}
                                        >
                                            Guardar
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>



        </div>
    )
}