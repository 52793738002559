/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon } from '@heroicons/react/solid'
import { CheckIcon, ChevronDownIcon, AdjustmentsIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { Menu, Listbox, Transition, Dialog } from '@headlessui/react'
import { MailIcon, PhoneIcon, EyeIcon } from '@heroicons/react/outline'




import { Fragment, useEffect, useRef, useState } from 'react';
import HttpService from '../services/HttpService';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}




export default function Channels(props) {

    const [canales, setCanales] = useState([]);

    const [open, setOpen] = useState(false)

    const [id, setId] = useState("")
    const [nombreCanal, setNombreCanal] = useState("")

    const [cuentas, setCuentas] = useState([]);
    const [cuentaSelected, setCuentaSelected] = useState({})

    const [cifrados, setCifrados] = useState([])

    const [validateNombreCanalError, setValidateNombreCanalError] = useState();
    const [validateCuentaError, setValidateCuentaError] = useState();

    const [modalTitle, setModalTitle] = useState();

    const validForm = useRef(true);
    const creating = useRef(true);


    useEffect(() => {
        props.handleTitulo("Canales de cobro");
        getCanales();
        getCatalogos();
    }, []);

    const getCanales = () => {
        HttpService.getAxiosClient().get('https://gotpaymx.com/api/codi/canales')
            .then(res => {
                console.log(res)
                setCanales(res.data)
            })
    }

    const getCatalogos = () => {
        HttpService.getAxiosClient().get('https://gotpaymx.com/api/codi/catalogos/canales')
            .then(res => {
                setCuentas(res.data.cuentas);
                console.log(res)
            })
    }

    const mostrarCifrado = (canal) => {
        HttpService.getAxiosClient().get(`https://gotpaymx.com/api/codi/canales/${canal.id}/secret`)
            .then(res => {
                canales.find(x => x.id === canal.id).clientSecret = res.data;
                setCanales([...canales]);
            })
        setCifrados([...cifrados, canal])
    }

    const handleNombreCanal = (nombreCanal) => {
        setNombreCanal(nombreCanal);
        setValidateNombreCanalError(null)
    }

    const handleCuentaSelected = (cuenta) => {
        setCuentaSelected(cuenta);
        setValidateCuentaError(null)
    }

    const saveChannel = () => {

        validateForm();
        if (validForm.current) {
            if (creating.current) {
                var url = 'https://gotpaymx.com/api/codi/canales';
                HttpService.getAxiosClient().post(url, {
                    nombreCanal: nombreCanal,
                    idCuenta: cuentaSelected.id
                })
                    .then(res => {
                        console.log(res)
                        getCanales()
                        setOpen(false)
                    })
            } else {
                var url = `https://gotpaymx.com/api/codi/canales/${id}`;
                HttpService.getAxiosClient().post(url, {
                    nombreCanal: nombreCanal,
                    idCuenta: cuentaSelected.id
                })
                    .then(res => {
                        console.log(res)
                        getCanales()
                        setOpen(false)
                    })
            }
            
        }
        
    }

    const validateForm = () => {
        validForm.current = true;

        if (!nombreCanal.trim()){
            setValidateNombreCanalError("Debe especificar un nombre para el canal")
            validForm.current = false;
        }
        console.log(cuentaSelected)
        if(!cuentaSelected.id){
            setValidateCuentaError("Debe seleccionar una cuenta")
        }

    }

    const editar = (canal) => {
        setOpen(true);
        creating.current = false;
        setNombreCanal(canal.nombreCanal);
        console.log(canal)
        setCuentaSelected(cuentas.find(x => x.id === canal.cuenta))
        setId(canal.id)

    }

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8">

            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <p className="mt-2 text-sm text-gray-700">
                        Los canales de cobro son configuraciones que permiten realizar operaciones CoDi.
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        type="button"
                        onClick={() => { creating.current = true; setOpen(true); setModalTitle("Agregar canal de cobro"); setNombreCanal("") }}
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Agregar canal de cobro
                    </button>
                </div>
            </div>
            <ul role="list" className="grid grid-cols-1 gap-6 md:grid-cols-1 lg:grid-cols-2 ">
                {canales.map((canal) => (
                    <li key={canal.id} className="col-span-1 divide-y divide-gray-300 rounded-lg shadow-xl mt-8 bg-indigo-200">

                        <div className="px-6 py-4 flex flex-1 items-center justify-between">
                            <p className="text-md font-medium text-gray-900 uppercase">{canal.nombreCanal}</p>
                            <Menu as="div" className="flex-shrink-0 pr-2 relative inline-block">
                                <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                    <span className="sr-only">Open options</span>
                                    <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                    href="#" onClick={(e) => {e.preventDefault(); editar(canal)}}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Editar
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </div>
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Eliminar
                                                    </a>
                                                )}
                                            </Menu.Item>
                                           
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>

                        <div className="rounded-b-lg bg-white  divide-y divide-gray-300 ">
                            <div className="px-6 py-4" >
                                <p className="text-sm"><span className="text-gray-900">Id:</span><span className="text-gray-500 ml-3">{canal.id}</span></p>
                                <p className="text-sm mt-1"><span className="text-gray-900">Cuenta:</span><span className="text-gray-500 ml-3">{canal.nombreCuenta}</span></p>
                            </div>

                            <div className="px-6 py-4 rounded-b-lg bg-white">
                                <div className="flex text-sm"><span className=" text-gray-900">Client ID:</span><span className="ml-3 text-gray-400"> {canal.clientId}</span></div>
                                <div className="flex text-sm mt-1"><span className=" text-gray-900">Client Secret:</span>
                                    <span className="ml-3 text-gray-400">
                                        {(cifrados.some(e => e.id === canal.id))
                                            ? canal.clientSecret
                                            : "***************************************"
                                        }
                                    </span><a href="#" onClick={() => mostrarCifrado(canal)}><EyeIcon className="ml-3 h-5 w-5 text-gray-400" /></a></div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>

                                        <Dialog.Title as="h3" className="text-lg text-center font-medium leading-6 text-gray-900 ">
                                        {modalTitle}
                                        </Dialog.Title>
                                        <div className="mt-6 ">
                                            <div className="">
                                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                                    Nombre del Canal
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="nombreCanal"
                                                        id="nombreCanal"
                                                        value={nombreCanal}
                                                        onChange={(e) => handleNombreCanal(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs font-mono"
                                                    />
                                                    <span className="inline-flex text-xs text-red-700">{validateNombreCanalError}</span>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <Listbox value={cuentaSelected} onChange={handleCuentaSelected}>
                                                    {({ open }) => (
                                                        <>
                                                            <Listbox.Label className="block text-sm font-medium text-gray-700">Cuenta</Listbox.Label>
                                                            <div className="relative mt-1 w-56">
                                                                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                                    <span className="inline-flex w-full truncate">
                                                                        <span className="truncate">{cuentaSelected.nombre}</span>
                                                                    </span>
                                                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                    </span>
                                                                </Listbox.Button>

                                                                <Transition
                                                                    show={open}
                                                                    as={Fragment}
                                                                    leave="transition ease-in duration-100"
                                                                    leaveFrom="opacity-100"
                                                                    leaveTo="opacity-0"
                                                                >
                                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                        {cuentas.map((cuenta) => (
                                                                            <Listbox.Option
                                                                                key={cuenta.id}
                                                                                className={({ active }) =>
                                                                                    classNames(
                                                                                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                    )
                                                                                }
                                                                                value={cuenta}
                                                                            >
                                                                                {({ cuentaSelected, active }) => (
                                                                                    <>
                                                                                        <div className="flex">
                                                                                            <span className={classNames(cuentaSelected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                                                                {cuenta.nombre}
                                                                                            </span>
                                                                                        </div>

                                                                                        {cuentaSelected ? (
                                                                                            <span
                                                                                                className={classNames(
                                                                                                    active ? 'text-white' : 'text-indigo-600',
                                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                                )}
                                                                                            >
                                                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            </span>
                                                                                        ) : null}
                                                                                    </>
                                                                                )}
                                                                            </Listbox.Option>
                                                                        ))}
                                                                    </Listbox.Options>
                                                                </Transition>
                                                            </div>
                                                        </>
                                                    )}
                                                </Listbox>
                                                <span className="inline-flex text-xs text-red-700">{validateCuentaError}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-12 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                            onClick={() => saveChannel()}
                                        >
                                            Guardar
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>


        </div>
    )
}