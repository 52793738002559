import React from 'react'
import { Link } from 'react-router-dom'
import RenderOnRole from './RenderOnRole'


import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    CashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    OfficeBuildingIcon,
    SearchIcon,
    DotsVerticalIcon,
    SelectorIcon
} from '@heroicons/react/solid'

import {
    CurrencyDollarIcon,
    BellIcon,
    ClockIcon,
    CogIcon,
    CreditCardIcon,
    DocumentReportIcon,
    HomeIcon,
    MenuAlt1Icon,
    QuestionMarkCircleIcon,
    ScaleIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    XIcon,
    CollectionIcon,
    MenuIcon,
    ViewListIcon,
    ClipboardListIcon,
    QrcodeIcon,
    KeyIcon,
    PlayIcon,
    UsersIcon,
    RefreshIcon
} from '@heroicons/react/outline'

const navigation = [
    { name: 'Home', href: '/', icon: HomeIcon, current: true },
    { name: 'Transacciones', href: '/transacciones', icon: ClipboardListIcon, current: false },
/*     { name: 'Cobrar QR / PUSH', href: '/canales', icon: QrcodeIcon, current: false },
    { name: 'Cobros Recurrentes', href: '#', icon: RefreshIcon, current: false }, */
]

const admin = [
    { name: 'Configuracion', href: '/configuracion', icon: CogIcon, current: true },
    { name: 'Cuentas clabe', href: '/cuentas', icon: CurrencyDollarIcon, current: false },
    { name: 'Canales de cobro', href: '/canales', icon: CollectionIcon, current: false },
    { name: 'Usuarios', href: '/usuarios', icon: UsersIcon, current: false },
]

const developers = [
    // { name: 'Credenciales API', href: '#', icon: KeyIcon, bgColorClass: 'bg-green-500' },
    { name: 'Webhooks', href: '/webhooks', icon: PlayIcon, bgColorClass: 'bg-yellow-500' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Sidebar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex z-40">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <img
                                        className="h-8 w-auto"
                                        src="https://tailwindui.com/img/logos/workflow-logo-purple-500-mark-gray-700-text.svg"
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                    <nav className="px-2">
                                        <div className="space-y-1">
                                            {navigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className={classNames(
                                                        item.current
                                                            ? 'bg-gray-100 text-gray-900'
                                                            : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
                                                        'group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md'
                                                    )}
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                            'mr-3 flex-shrink-0 h-6 w-6'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 lg:bg-gray-100">
                <div className="flex items-center justify-center flex-shrink-0 px-6">
                    <img
                        className="h-20 w-auto"
                        src="gotpay.svg"
                        alt="Gotpay SAS de CV"
                    />
                </div>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="mt-4 h-0 flex-1 flex flex-col overflow-y-auto">
                    {/* User account dropdown */}

                    {/* Sidebar Search */}
                    <div className="px-3 mt-5">
                        <label htmlFor="search" className="sr-only">
                            Search
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div
                                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                                aria-hidden="true"
                            >
                                <SearchIcon className="mr-3 h-4 w-4 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                                type="text"
                                name="search"
                                id="search"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-9 sm:text-sm border-gray-300 rounded-md"
                                placeholder="Search"
                            />
                        </div>
                    </div>
                    {/* Navigation */}
                    <nav className="px-3 mt-6">
                        <div className="space-y-1">
                            {navigation.map((item) => (

                                <Link
                                    key={item.name}
                                    to={item.href}
                                    className={classNames(
                                        item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
                                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    <item.icon
                                        className={classNames(
                                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                        <RenderOnRole roles={['admin']}>
                            <div className="mt-8">
                                {/* Secondary navigation */}
                                <h3
                                    className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                                    id="desktop-teams-headline"
                                >
                                    Admin
                                </h3>
                                <div className="mt-1 space-y-1" role="group" aria-labelledby="desktop-teams-headline">
                                    {admin.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                    'mr-3 flex-shrink-0 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="truncate">{item.name}</span>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </RenderOnRole>
                        <div className="mt-8">
                            {/* Secondary navigation */}
                            <h3
                                className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                                id="desktop-teams-headline"
                            >
                                Developers
                            </h3>
                            <div className="mt-1 space-y-1" role="group" aria-labelledby="desktop-teams-headline">
                                {developers.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
                                    >
                                        <item.icon
                                            className={classNames(
                                                item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                'mr-3 flex-shrink-0 h-6 w-6'
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span className="truncate">{item.name}</span>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Sidebar