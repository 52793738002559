import { Fragment, useState } from 'react'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Sidebar from './Sidebar'
import Header from './Header'
import Dashboard from './Dashboard'
import Transactions from './Transactions'
import Channels from './Channels'
import Cuentas from './Cuentas'
import Configuration from './Configuration'
import Webhooks from './Webhooks'
import Usuarios from './Usuarios'


const routes = [
    {
        path: "/",
        component: Transactions,
    },
    {
        path: "/transacciones",
        component: Transactions
    },
    {
        path: "/canales",
        component: Channels
    },
    {
        path: "/cuentas",
        component: Cuentas
    },
    {
        path: "/webhooks",
        component: Webhooks
    },
    {
        path: "/configuracion",
        component: Configuration
    },
    {
        path: "/usuarios",
        component: Usuarios
    }
];

export default function Home() {
    const [titulo, setTitulo] = useState("Inicio")

    return (
        <>
            <div className="min-h-full">
                <Sidebar />

                {/* Main column */}
                <div className="lg:pl-64 flex flex-col">
                    <main className="flex-1">
                        {/* Page title & actions */}
                        <Header titulo={titulo}/>
                            <Routes>
                                {routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={<route.component handleTitulo={setTitulo}/>}
                                    />
                                ))}
                            </Routes>
                    </main>
                </div>
            </div>
        </>
    )
}
