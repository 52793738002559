/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon } from '@heroicons/react/solid'
import { CheckIcon, ChevronDownIcon, AdjustmentsIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { Menu, Listbox, Transition, Dialog } from '@headlessui/react'
import { MailIcon, PhoneIcon, EyeIcon } from '@heroicons/react/outline'
import { PlusIcon } from '@heroicons/react/solid'
import { BANCOS } from '../Constantes'





import { Fragment, useEffect, useRef, useState } from 'react';
import HttpService from '../services/HttpService';

const statusStyles = {
    1: 'bg-green-100 text-green-800',
    '-1': 'bg-yellow-100 text-yellow-800',
    4: 'bg-gray-100 text-gray-800',
    2: 'bg-red-100 text-red-800',
}

const statusNames = {
    1: 'Pagado',
    '-1': 'Pendiente',
    4: 'Pospuesto',
    2: 'Rechazado',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const tipoCodi = [
    { id: '0', nombre: 'Todo' },
    { id: 'QR', nombre: 'QR' },
    { id: 'MESSAGE', nombre: 'MESSAGE' }
]

const estados = [
    { id: '0', nombre: 'Todo' },
    { id: '-1', nombre: 'Pendiente' },
    { id: '1', nombre: 'Pagado' },
    { id: '4', nombre: 'Pospuesto' },
    { id: '2', nombre: 'Rechazado' }
]



export default function Webhooks(props) {

    const [open, setOpen] = useState(false)



    const [id, setId] = useState("")
    const [nombreWebhook, setNombreWebhook] = useState("")
    const [urlWebhook, setUrlWebhook] = useState("")

    const [webhooks, setWebhooks] = useState([]);




    const [modalTitle, setModalTitle] = useState();

    const [validateNombreWebhookError, setValidateNombreWebhookError] = useState();
    const [validateUrlWebhookError, setValidateUrlWebhookError] = useState();


    const validForm = useRef(true);

    const creating = useRef(true);


    useEffect(() => {
        props.handleTitulo("Webhooks");
        getWebhooks();
    }, []);

    const getWebhooks = () => {
        HttpService.getAxiosClient().get('https://gotpaymx.com/api/codi/webhooks')
            .then(res => {
                console.log(res)
                setWebhooks(res.data)
            })
    }





    const handleClabe = (clabe) => {
  

    }

    const handleNombre = (nombreCuenta) => {

    }

    const guardarWebhook = () => {
        validateForm();

        if (validForm.current) {
            if (creating.current) {
                var url = 'https://gotpaymx.com/api/codi/webhooks';
                HttpService.getAxiosClient().post(url, {
                    nombreWebhook: nombreWebhook,
                    url: urlWebhook,
                })
                    .then(res => {
                        console.log(res)
                        getWebhooks()
                        setOpen(false)
                    })
            } else {
                var url = `https://gotpaymx.com/api/codi/webhooks/${id}`;
                HttpService.getAxiosClient().post(url, {
                    nombreWebhook: nombreWebhook,
                    url: urlWebhook,
                })
                    .then(res => {
                        console.log(res)
                        getWebhooks()
                        setOpen(false)
                    })
            }
        }
    }

    const validateForm = () => {
        validForm.current = true;

        if(!nombreWebhook?.trim() ){
            setValidateNombreWebhookError("Debe ingresar el nombre del webhook")
            validForm.current = false;
        } else {
            setValidateNombreWebhookError(null)
        }

        if(!urlWebhook?.trim() ){
            setValidateUrlWebhookError("Debe ingresar la url del webhook")
            validForm.current = false;
        } else {
            setValidateUrlWebhookError(null)
        }


    }

    const preValidateClabe = (clabe) => {


    }

    const editar = (webhook) => {
        creating.current = false;
        setId(webhook.idWebhook)
        setNombreWebhook(webhook.nombreWebhook)
        setUrlWebhook(webhook.url)
        setOpen(true);

    }


    return (
        <div className="px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <p className="mt-2 text-sm text-gray-700">
                        Webhooks configurados para notificar los cambios de estado de sus operaciones
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        type="button"
                        onClick={() => { creating.current = true; setOpen(true); setModalTitle("Agregar cuenta clabe");   }}
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Agregar webhook
                    </button>
                </div>
            </div>



            <ul role="list" className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 ">
                {webhooks.map((webhook) => (
                    <li key={webhook.idWebhook} className="col-span-1 divide-y divide-gray-300 rounded-lg shadow-xl mt-8 bg-indigo-200">

                        <div className="px-6 py-4 flex flex-1 items-center justify-between">
                            <p className="text-md font-medium text-gray-900 uppercase">{webhook.nombreWebhook}</p>
                            <Menu as="div" className="flex-shrink-0 pr-2 relative inline-block">
                                <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                    <span className="sr-only">Open options</span>
                                    <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#" onClick={(e) => {e.preventDefault(); editar(webhook)}}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Editar
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </div>
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Eliminar
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>

                        <div className="rounded-b-lg bg-white ">
                            <div className="px-6 py-4" >
                                <p className="text-sm"><span className="text-gray-900">Id:</span><span className="text-gray-500 ml-3">{webhook.idWebhook}</span></p>
                                <p className="text-sm mt-1"><span className="text-gray-900">Url:</span><span className="text-gray-500 ml-3">{webhook.url}</span></p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <Dialog.Title as="h3" className="text-lg text-center font-medium leading-6 text-gray-900 ">
                                            {modalTitle}
                                        </Dialog.Title>
                                        <div className="mt-6 ">
                                            <div className="">
                                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                                    Nombre del webhook
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="nombreWebhook"
                                                        id="nombreWebhook"
                                                        value={nombreWebhook}
                                                        onChange={(e) => setNombreWebhook(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs font-mono"
                                                    />
                                                     <span className="inline-flex text-xs text-red-700">{validateNombreWebhookError}</span>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                                    Url
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="url"
                                                        id="url"
                                                        value={urlWebhook}
                                                        onChange={(e) => setUrlWebhook(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs font-mono"
                                                    />
                                                    <span className="inline-flex text-xs text-red-700">{validateUrlWebhookError}</span>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                            onClick={() => guardarWebhook()}
                                        >
                                            Guardar
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>



        </div>
    )
}