import HttpService from '../services/HttpService';
import UserService from '../services/UserService';
import { Fragment, useEffect, useRef, useState } from 'react';

export default function SignUp() {



    const [nombre, setNombre] = useState("")
    const [apellidos, setApellidos] = useState("")
    const [correo, setCorreo] = useState("")
    const [password, setPassword] = useState("")
    const [confirmacion, setConfirmacion] = useState("")
    const [validateCorreoError, setValidateCorreoError] = useState()

    const [userCreated, setUserCreated] = useState(false)


    const validForm = useRef(true);


    useEffect(() => {

    }, []);

    const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const guardarEmpresa = (e) => {
        e.preventDefault();

        validateForm();
        if (validForm.current) {
            var url = 'https://gotpaymx.com/api/codi/empresas';
            HttpService.getAxiosClientRegistro().post(url, {
                nombre: nombre,
                apellidos: apellidos,
                correo: correo,
                password: password
            })
                .then(res => {
                    setUserCreated(true);
                    console.log(res)
                })
        }
    }

    const login = () => {
        UserService.doLogin();

    }

    const validateForm = () => {
        validForm.current = true;
        if (!isEmail(correo)) {
            setValidateCorreoError("Debe ingresar un correo valido")
            validForm.current = false;
        } else {
            setValidateCorreoError(null)
        }
    }

    return (
        <>
            <div className="flex min-h-full justify-center py-8">
                <div className="flex flex-1 flex-col  py-6 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 shadow-lg sm:rounded-md bg-white">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div className="flex">
                            <img
                                className="h-24 w-auto"
                                src="gotpay.svg"
                                alt="Gotpay SAS de CV"
                            />
                            <h2 className="ml-10 mt-4 text-xl font-bold tracking-tight text-gray-900">Comienza cobrar con CoDi y ¡deja de pagar comisiones!</h2>

                        </div>
                        {!userCreated &&
                            <div className="mt-6">
                                <div>
                                    <div className="relative mt-6">
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="w-full border-t border-gray-300" />
                                        </div>
                                        <div className="relative flex justify-center text-sm">
                                            <span className="bg-white px-2 text-gray-500">ingresa tus datos</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <form action="#" method="POST" onSubmit={guardarEmpresa} className="grid grid-cols-2">
                                        <div className="mr-2">
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                Nombres
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    id="nombre"
                                                    name="nomnre"
                                                    type="text"
                                                    value={nombre}
                                                    onChange={(e) => setNombre(e.target.value)}
                                                    required
                                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-2">
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                Apellidos
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    id="apellidos"
                                                    name="apellidos"
                                                    type="text"
                                                    value={apellidos}
                                                    onChange={(e) => setApellidos(e.target.value)}
                                                    required
                                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-span-full mt-6">
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                Correo
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    value={correo}
                                                    onChange={(e) => { setCorreo(e.target.value); setValidateCorreoError(null) }}
                                                    required
                                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                />
                                                <span className="inline-flex text-xs text-red-700">{validateCorreoError}</span>
                                            </div>
                                        </div>

                                        <div className="space-y-1 col-span-full mt-6">
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                Password
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>

                                        <div className="space-y-1 col-span-full mt-6">
                                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                Confirmar password
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    id="password-confirm"
                                                    name="password-confirm"
                                                    type="password"
                                                    value={confirmacion}
                                                    onChange={(e) => setConfirmacion(e.target.value)}
                                                    required
                                                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>

                                        <div className="space-y-1 col-span-full mt-6">
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Registro
                                            </button>
                                        </div>
                                        <div className="mt-6 text-center text-sm col-span-full">
                                            <p>
                                                <a href="#"
                                                    onClick={(e) => { e.preventDefault(); window.history.replaceState("", "", "/"); UserService.doLogin(); }}
                                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                                    o accede si ya tienes una cuenta
                                                    <span aria-hidden="true"> &rarr;</span>
                                                </a>
                                            </p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                        {userCreated &&
                            <div className="mt-6">
                                <div>
                                    <div className="relative mt-6">
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="w-full border-t border-gray-300" />
                                        </div>
                                        <div className="relative flex justify-center text-sm">
                                            <span className="bg-white px-2 text-gray-500">Usuario creato con exito</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6">

                                    <div className="mr-2">
                                        ¡Creamos tu registro exitosamente!
                                    </div>


                                    <div className="mt-6 text-center text-sm col-span-full">
                                        <p>
                                            <a href="#"
                                                onClick={(e) => { e.preventDefault(); window.history.replaceState("", "", "/"); UserService.doLogin(); }}
                                                className="font-medium text-indigo-600 hover:text-indigo-500">
                                                Accede con tu cuenta
                                                <span aria-hidden="true"> &rarr;</span>
                                            </a>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="relative hidden max-w-lg flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="qrsignup.jpg"
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}