/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon } from '@heroicons/react/solid'
import { CheckIcon, ChevronDownIcon, AdjustmentsIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { Menu, Listbox, Transition } from '@headlessui/react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Fragment, useEffect, useState } from 'react';
import HttpService from '../services/HttpService';

const statusStyles = {
    1: 'bg-green-100 text-green-800',
    '-1': 'bg-yellow-100 text-yellow-800',
    4: 'bg-gray-100 text-gray-800',
    2: 'bg-red-100 text-red-800',
}

const statusNames = {
    1: 'Pagado',
    '-1': 'Pendiente',
    4: 'Pospuesto',
    2: 'Rechazado',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const people = [
    { name: 'Wade Cooper', username: '@wadecooper' },
    { name: 'Arlene Mccoy', username: '@arlenemccoy' },
    { name: 'Devon Webb', username: '@devonwebb' },
    { name: 'Tom Cook', username: '@tomcook' },
    { name: 'Tanya Fox', username: '@tanyafox' },
    { name: 'Hellen Schmidt', username: '@hellenschmidt' },
    { name: 'Caroline Schultz', username: '@carolineschultz' },
    { name: 'Mason Heaney', username: '@masonheaney' },
    { name: 'Claudie Smitham', username: '@claudiesmitham' },
    { name: 'Emil Schaefer', username: '@emilschaefer' },
]

const tipoCodi = [
    { id: '0', nombre: 'Todo' },
    { id: 'QR', nombre: 'QR' },
    { id: 'MESSAGE', nombre: 'MESSAGE' }
]

const estados = [
    { id: '0', nombre: 'Todo' },
    { id: '-1', nombre: 'Pendiente' },
    { id: '1', nombre: 'Pagado' },
    { id: '4', nombre: 'Pospuesto' },
    { id: '2', nombre: 'Rechazado' }
]



export default function Transactions(props) {



    const [transactions, setTransactions] = useState([]);
    const [canales, setCanales] = useState([]);
    const [cuentas, setCuentas] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [selectedFields, setSelectedFields] = useState([])

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [canalSelected, setCanalSelected] = useState({})
    const [cuentaSelected, setCuentaSelected] = useState({})
    const [tipoSelected, setTipoSelected] = useState(tipoCodi[0]);
    const [estadoSelected, setEstadoSelected] = useState(estados[0]);

    useEffect(() => {
        props.handleTitulo("Transacciones");
        getTransacciones();
        getCatalogos();
    }, []);

    const getCatalogos = () => {
        HttpService.getAxiosClient().get('https://gotpaymx.com/api/codi/catalogos/canales')
            .then(res => {
                setCanales(res.data.canales);
                setCuentas(res.data.cuentas);
                console.log(res)
            })
    }

    const getTransacciones = () => {
        const customs = [];
        var params = {};
        if (estadoSelected.id !== '0') params.resultadoMensajeCobro = estadoSelected.id;
        if (tipoSelected.id !== '0') params.tipoCodi = tipoSelected.nombre;
        var url = 'https://gotpaymx.com/api/codi/transacciones';
        HttpService.getAxiosClient().get(url, { params })
            .then(res => {
                setTransactions(res.data);
                res.data.map((transaction) => {
                    Object.keys(transaction.metadata).map((custom) => {
                        if (!customs.includes(custom))
                            customs.push(custom);
                    })
                });
                setMetadata(customs);
            })
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
             

            <div className="flex gap-4 items-center my-8 ">
                <div >
                    <label className="block text-sm font-medium text-gray-700">
                        Fecha Inicio
                    </label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}  className="relative  cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" />
                </div>
                <div >
                    <label className="block text-sm font-medium text-gray-700">
                        Fecha Fin
                    </label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)}  className="relative  cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" />
                </div>
            </div>

            <div className="flex gap-4 items-center my-8 ">
                <div>
                    <Listbox value={canalSelected} onChange={setCanalSelected}>
                        {({ open }) => (
                            <>
                                <Listbox.Label className="block text-sm font-medium text-gray-700">Canal</Listbox.Label>
                                <div className="relative mt-1 w-72">
                                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span className="inline-flex w-full truncate">
                                            <span className="truncate">{canalSelected.nombre}</span>
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </Listbox.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {canales.map((canal) => (
                                                <Listbox.Option
                                                    key={canal.id}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                        )
                                                    }
                                                    value={canal}
                                                >
                                                    {({ canalSelected, active }) => (
                                                        <>
                                                            <div className="flex">
                                                                <span className={classNames(canalSelected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                                    {canal.nombre}
                                                                </span>
                                                            </div>

                                                            {canalSelected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active ? 'text-white' : 'text-indigo-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>
                <div>
                    <Listbox value={cuentaSelected} onChange={setCuentaSelected}>
                        {({ open }) => (
                            <>
                                <Listbox.Label className="block text-sm font-medium text-gray-700">Cuenta</Listbox.Label>
                                <div className="relative mt-1 w-72">
                                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span className="inline-flex w-full truncate">
                                            <span className="truncate">{cuentaSelected.nombre}</span>
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </Listbox.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {cuentas.map((cuenta) => (
                                                <Listbox.Option
                                                    key={cuenta.id}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                        )
                                                    }
                                                    value={cuenta}
                                                >
                                                    {({ cuentaSelected, active }) => (
                                                        <>
                                                            <div className="flex">
                                                                <span className={classNames(cuentaSelected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                                    {cuenta.nombre}
                                                                </span>
                                                            </div>

                                                            {cuentaSelected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active ? 'text-white' : 'text-indigo-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>
                <div>
                    <Listbox value={estadoSelected} onChange={setEstadoSelected}>
                        {({ open }) => (
                            <>
                                <Listbox.Label className="block text-sm font-medium text-gray-700">Estado del pago</Listbox.Label>
                                <div className="relative mt-1 w-32">
                                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span className="inline-flex w-full truncate">
                                            <span className="truncate">{estadoSelected.nombre}</span>
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </Listbox.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {estados.map((estado) => (
                                                <Listbox.Option
                                                    key={estado.id}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                        )
                                                    }
                                                    value={estado}
                                                >
                                                    {({ estadoSelected, active }) => (
                                                        <>
                                                            <div className="flex">
                                                                <span className={classNames(estadoSelected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                                    {estado.nombre}
                                                                </span>
                                                            </div>

                                                            {estadoSelected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active ? 'text-white' : 'text-indigo-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>
                <div>
                    <Listbox value={tipoSelected} onChange={setTipoSelected}>
                        {({ open }) => (
                            <>
                                <Listbox.Label className="block text-sm font-medium text-gray-700">Tipo de CoDi</Listbox.Label>
                                <div className="relative mt-1 w-32">
                                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span className="inline-flex w-full truncate">
                                            <span className="truncate">{tipoSelected.nombre}</span>
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </Listbox.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {tipoCodi.map((tipo) => (
                                                <Listbox.Option
                                                    key={tipo.id}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                        )
                                                    }
                                                    value={tipo}
                                                >
                                                    {({ tipoSelected, active }) => (
                                                        <>
                                                            <div className="flex">
                                                                <span className={classNames(tipoSelected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                                                    {tipo.nombre}
                                                                </span>
                                                            </div>

                                                            {tipoSelected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active ? 'text-white' : 'text-indigo-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>

                <div className="mt-6">
                    <button
                        type="button"
                        onClick={getTransacciones}
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Buscar
                    </button>
                </div>
            </div>




            <div className="flex justify-end mt-8">
                <button
                    type="button"
                    className="hidden inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                >
                    Exportar csv
                </button>
                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className="inline-flex justify-center rounded-md bg-indigo-600 bg-opacity-60 px-2 py-2  hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">

                            <AdjustmentsIcon
                                className="h-5 w-5 text-violet-200 hover:text-violet-100"
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 mt-2 w-60 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1 ">
                                {metadata.map((customField) => (
                                    <div className="flex" key={customField}>
                                        <input
                                            type="checkbox"
                                            className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            id={customField}
                                            value={customField}
                                            checked={selectedFields.includes(customField)}
                                            onChange={(e) =>
                                                setSelectedFields(
                                                    e.target.checked
                                                        ? [...selectedFields, customField]
                                                        : selectedFields.filter((p) => p !== customField)
                                                )
                                            }
                                        >
                                        </input>

                                        <label htmlFor={customField} className="group flex w-full items-center rounded-md px-2 text-sm"
                                        >
                                            {customField}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>

            <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900"
                                        >
                                            ID
                                        </th>
                                        {metadata.map((customField) => (
                                            (selectedFields.includes(customField) &&
                                                <th
                                                    key={customField}
                                                    scope="col"
                                                    className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900 "
                                                >
                                                    {customField.toUpperCase()}
                                                </th>
                                            )
                                        ))}
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900 "
                                        >
                                            CANAL
                                        </th>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
                                        >
                                            TIPO
                                        </th>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-right text-xs font-semibold text-gray-900"
                                        >
                                            FECHA SOLICITUD
                                        </th>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-right text-xs font-semibold text-gray-900"
                                        >
                                            FECHA PROCESAMIENTO
                                        </th>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-right text-xs font-semibold text-gray-900"
                                        >
                                            MONTO
                                        </th>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
                                        >
                                            ESTADO
                                        </th>
                                        <th
                                            scope="col"
                                            className="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900"
                                        >
                                            CONCILIADO
                                        </th>

                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white font-mono">
                                    {transactions.map((transaction) => (
                                        <tr key={transaction.id}>
                                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-xs text-gray-500 ">
                                                <div className='has-tooltip'>
                                                    <span className='tooltip p-1 rounded md:bg-blue-200'>{transaction.id}</span>
                                                    {transaction.id.substring(0, 8) + "..."}
                                                </div>

                                            </td>
                                            {metadata.map((customField) => (
                                                (selectedFields.includes(customField) &&
                                                    <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500" key={customField}>
                                                        {transaction.metadata[customField]}
                                                    </td>
                                                )
                                            ))}
                                            <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
                                                {transaction.nombreCanal}
                                            </td>
                                            <td className="whitespace-nowrap px-2 py-2 text-xs font-medium text-gray-500">
                                                {transaction.tipoCodi}
                                            </td>
                                            <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500 text-right">{new Date(transaction.fechaHora).toLocaleString()}</td>
                                            <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500 text-right">{transaction.fechaHoraProcesamiento ? new Date(transaction.fechaHoraProcesamiento).toLocaleString() : ""}</td>


                                            <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500 text-right">
                                                <span className="text-gray-900 font-medium">{transaction.monto.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </span>
                                                MXN
                                            </td>
                                            <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
                                                <span
                                                    className={classNames(
                                                        statusStyles[transaction.estado],
                                                        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                                    )}
                                                >
                                                    {statusNames[transaction.estado]}
                                                </span>
                                            </td>
                                            <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500">{transaction.conciliado}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}