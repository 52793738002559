import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import HomeAnonymous from "./components/HomeAnonymous";
import RenderOnAnonymous from "./components/RenderOnAnonymous";
import RenderOnAuthenticated from "./components/RenderOnAuthenticated";

function App() {


  return (
    <div>
      <BrowserRouter>
        <RenderOnAnonymous>
          <HomeAnonymous />
        </RenderOnAnonymous>
        <RenderOnAuthenticated>
          <Home />
        </RenderOnAuthenticated>
      </BrowserRouter>
    </div>
  );
}

export default App;
