import ReactDOM from 'react-dom/client';
import App from './App';
import UserService from "./services/UserService";
import HttpService from './services/HttpService';
import './index.css';
import 'tw-elements';


const root = ReactDOM.createRoot(document.getElementById('root'));
const renderApp = () => root.render(<App/>);
UserService.initKeycloak(renderApp);
HttpService.configure();

