import HttpService from '../services/HttpService';
import UserService from '../services/UserService';
import { Fragment, useEffect, useRef, useState } from 'react';

export default function SignUp() {



    const [nombre, setNombre] = useState("")
    const [apellidos, setApellidos] = useState("")
    const [correo, setCorreo] = useState("")
    const [password, setPassword] = useState("")
    const [confirmacion, setConfirmacion] = useState("")
    const [validateCorreoError, setValidateCorreoError] = useState()

    const [userCreated, setUserCreated] = useState(false)


    const validForm = useRef(true);


    useEffect(() => {

    }, []);

    const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const guardarEmpresa = (e) => {
        e.preventDefault();

        validateForm();
        if (validForm.current) {
            var url = 'https://gotpaymx.com/api/codi/empresas';
            HttpService.getAxiosClientRegistro().post(url, {
                nombre: nombre,
                apellidos: apellidos,
                correo: correo,
                password: password
            })
                .then(res => {
                    setUserCreated(true);
                    console.log(res)
                })
        }
    }

    const login = () => {
        UserService.doLogin();

    }

    const validateForm = () => {
        validForm.current = true;
        if (!isEmail(correo)) {
            setValidateCorreoError("Debe ingresar un correo valido")
            validForm.current = false;
        } else {
            setValidateCorreoError(null)
        }
    }

    return (
        <>





            <div class="w-full bg-gray-100 border-b">
                <div class="flex">
                    <div class="bg p-5">
                        <h1 class="text-2xl">Gotpay - Documentacion de Api</h1>
                    </div>

                    <div class="flex-1 flex  w-full px-10">
                        <form class=" w-full flex md:ml-0" action="#" method="GET">
                            <label for="search-field" class="sr-only">Search</label>
                            <div class="relative w-1/2 text-gray-400 focus-within:text-gray-600">

                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="w-full">

                <div class="flex w-full">


                    <div class=" w-1/2 border-r p-5">

                        <nav class="flex-1 space-y-1">

                            <div>
                                <a href="#introduccion"
                                    class="text-gray-900 group w-full flex items-center pr-2 py-2 text-sm font-medium ">
                                    Introduccion
                                </a>
                            </div>

                            <div>
                                <a href="#autenticacion"
                                    class="text-gray-900 group w-full flex items-center pr-2 py-2 text-sm font-medium ">
                                    Autenticación
                                </a>
                            </div>

                            <div>
                                <a href="#operaciones"
                                    class="text-gray-900 group w-full flex items-center pr-2 py-2 text-sm font-medium ">
                                    Operaciones CoDi
                                </a>
                            </div>

                            <div class="space-y-1">
                                <div class="space-y-1 ">
                                    <a href="#enviarmensaje"
                                        class="group w-full flex items-center pl-4 pr-2 py-2 text-sm font-medium text-gray-600  hover:text-gray-900 ">
                                        Enviar mensaje de cobro
                                    </a>
                                </div>
                                <div class="space-y-1 ">
                                    <a href="#"
                                        class="group w-full flex items-center pl-4 pr-2 py-2 text-sm font-medium text-gray-600  hover:text-gray-900 ">
                                        Generar codigo QR
                                    </a>
                                    <a href="#"
                                        class="group w-full flex items-center pl-4 pr-2 py-2 text-sm font-medium text-gray-600  hover:text-gray-900 ">
                                        Consultar estado de una operacion
                                    </a>
                                    <a href="#"
                                        class="group w-full flex items-center pl-4 pr-2 py-2 text-sm font-medium text-gray-600  hover:text-gray-900 ">
                                        Listar transacciones
                                    </a>
                                </div>
                            </div>
                        </nav>

                    </div>


                    <div class="px-10  flex-1 flex h-full ">

                        <div class="flex flex-col space-y-10">


                            <section id="introduccion">
                                <div class="border-b py-4 space-y-4 bg">
                                    <h1 class="text-3xl tracking-wide">Introduccion</h1>
                                </div>
                            </section>


                            <section>
                                <div class=" space-y-4 ">
                                    <p class="text-black text-gray-500">La API de Gotpay está organizada en torno a REST. Nuestra API tiene URLs predecibles orientadas a los recursos, acepta peticiones en formato JSON, y las respuestas devueltas son formato JSON y utiliza códigos de respuesta HTTP, autenticación y verbos estándares.</p>
                                </div>
                            </section>

                            <section id="autenticacion">
                                <div class="border-b py-4 space-y-4">
                                    <h1 class="text-3xl tracking-wide">Autenticación</h1>
                                </div>
                            </section>
                            <section>
                                <div class=" space-y-4">
                                    <p class="text-black text-gray-500">La API de Gotpay utiliza el protocolo OAuth 2.0 para autenticación y autorización. <br /><br />
                                        Para comenzar, es necesario que registre una cuenta en nuestra plataforma y obtenga las credenciales de cliente OAuth 2.0 la seccion de canales de cobro en la consola de administración.
                                        Las credenciales consisten en  su client_id y client_secret mismos que usara para su interaccion con la Api. </p>
                                </div>
                            </section>

                            <section>
                                <div class="py-4 space-y-4">
                                    <h1 class="text-2xl tracking-wide text-gray-700">Obtener credenciales</h1>
                                    <p class="text-black text-gray-500">Para realizar operaciones con la Api es necesario primero obtener el token de autorizacion, este se consigue haciendo la siguiente peticion.  </p>


                                    <code class="block overflow-x-scroll bg-gray-100 bg-opacity-50 text-gray-700 rounded p-4 whitespace-pre">

                                        curl --location --request POST 'https://gotpaymx.com/auth/realms/gotpay/protocol/openid-connect/token' \ <br />
                                        --header 'Content-Type: application/x-www-form-urlencoded' \<br />
                                        --data-urlencode 'grant_type=client_credentials' \<br />
                                        --data-urlencode 'client_id=client_id_de_la_seccion_canales_de_cobro' \<br />
                                        --data-urlencode 'client_secret=client_secret_de_la_seccion_canales_de_cobro'<br />

                                    </code>
                                    <p class="text-black text-gray-500">Obtendra una respuesta como se muestra a continuacion  </p>
                                    <code class="block overflow-x-scroll bg-gray-100 bg-opacity-50 text-gray-700 rounded p-4 whitespace-pre">

                                        {'{'}<br />
                                        {' "access_token": "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJYYTZlNTBSUDI0OU55Q1lDcnRJdTlsSzQtN2pPRFBfNHlGNDhOWmxnaG5nIn0.eyJl...",'}<br />
                                        {' "expires_in": 86400,'}<br />
                                        {' "refresh_expires_in": 1800,'}<br />
                                        {' "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJiYzE2YjMwNy03Yzg3LTQ4ZjUtYTkwNi0zNmI4OGVkMTZkY2QifQ.eyJleHAiOjE2Njk...",'}<br />
                                        {' "token_type": "bearer",'}<br />
                                        {' "not-before-policy": 0,'}<br />
                                        {' "session_state": "207f264e-9e9f-43df-908f-7477f1ff770c",'}<br />
                                        {' "scope": "profile email"'}<br />
                                        {'}'}

                                    </code>

                                    <p class="text-black">El atributo access_token es el token de autorizacion que se debera usar en las siguientes llamadas al Api</p>

                                </div>
                            </section>

                            <section id="operaciones">
                                <div class="border-b py-4 space-y-4">
                                    <h1 class="text-3xl tracking-wide">Operaciones CoDi</h1>
                                </div>
                            </section>
                            <section>
                                <div class=" space-y-4">
                                    <p class="text-black text-gray-500">Una vez con el token disponible podemos realizar solicitudes de cobro mediante operaciones CoDi, existen dos tipos de operaciones por medio de mensajes de cobro y QR. </p>
                                </div>
                            </section>
                            <section id="enviarmensaje">
                                <div class="py-4 space-y-4">
                                    <h1 class="text-2xl tracking-wide text-gray-700">Enviar mensaje de cobro</h1>
                                    <p class="text-black text-gray-500">Para enviar un mensaje de cobro debe realizarse la siguiente peticion </p>


                                    <code class="block overflow-x-scroll bg-gray-100 bg-opacity-50 text-gray-700 rounded p-4 whitespace-pre">
                                        {'curl --location --request POST \'https://gotpaymx.com/api/codi/transacciones\' \\'}<br />
                                        {'    --header \'Authorization: Bearer <access_token>\' \\'}<br />
                                        {'   --header \'Content-Type: application/json\' \\'}<br />
                                        {'   --header \'Cookie: JSESSIONID=8C7D36DB822F118C31517DC4C7072241\' \\'}<br />
                                        {'   --data-raw \'{"tipoCodi":"MESSAGE",'}<br />
                                        {'   "monto":0.01,'}<br />
                                        {'   "celular":"5584001331",'}<br />
                                        {'   "concepto":"posponer",'}<br />
                                        {'   "referencia": 0}\''}<br />




                                    </code>
                                    <p class="text-black text-gray-500">Obtendra una respuesta como se muestra a continuacion  </p>
                                    <code class="block overflow-x-scroll bg-gray-100 bg-opacity-50 text-gray-700 rounded p-4 whitespace-pre">

                                        {'{'}<br />
                                        {'   "id": "cb403cbd-0752-4c1d-9cda-7d5a6e1b7680"'}<br />
                                        {'}'}<br />
                                    </code>

                                </div>
                            </section>

                            <section>
                                <div class="py-4 space-y-4">
                                    <h1 class="text-2xl tracking-wide text-gray-700">Posibles estados de respuesta</h1>

                                    <div class="flex flex-col">
                                        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                <div class="overflow-hidden border-b border-gray-200">
                                                    <table class="min-w-full divide-y divide-gray-500 text-gray-700">
                                                        <thead class="bg-gray-50">
                                                            <tr>
                                                                <th scope="col"
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                    Http Status
                                                                </th>
                                                                <th scope="col"
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                    Codigo Error
                                                                </th>
                                                                <th scope="col"
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                    Descripcion
                                                                </th>
                                                                <th scope="col"
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/5">
                                                                    Ejemplo respuesta
                                                                </th>

                                                            </tr>
                                                        </thead>

                                                        <tbody class="bg-white divide-y divide-gray-500">
                                                            <tr className="bg-green-50">
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    200
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">

                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-green-500">
                                                                    El mensaje de cobro de envio con exito, la respuesta incluye el id con el que se identifica la operacion.
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-green-500 ">
                                                                    {'{'}<br />
                                                                    {'"id": "13ed7c38-1bca-4e00-81a8-e7ac0005d674"'}<br />
                                                                    {'}'}<br />

                                                                </td>
                                                            </tr>
                                                            <tr className="bg-red-50">
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    400
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    1000

                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500">
                                                                    La cuenta no ha terminado de configurarse. Significa que no se ha actualizado los datos de la cuenta  en la seccion de configuracion de la consola de administracion.
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500 ">
                                                                    {' {'}<br />
                                                                    {' "timestamp": "25-11-2022 03:36:09",'}<br />
                                                                    {' "status": "BAD_REQUEST",'}<br />
                                                                    {' "statusCode": 400,'}<br />
                                                                    {' "code": 1000,'}<br />
                                                                    {' "message": "La cuenta no ha terminado de configurarse",'}<br />
                                                                    {'}'}<br />

                                                                </td>
                                                            </tr>
                                                            <tr className="bg-red-50">
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    400
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    1001
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500">
                                                                    Los parametros sin incorrectos. Alguno de los parametros no cumple con la validacion, en la respuesta se indentifica que parametros causaron el error.
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500 ">


                                                                </td>
                                                            </tr>
                                                            <tr className="bg-red-50">
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    400
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    1002
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500">
                                                                    El parametro tipoCodi debe ser MESSAGE o PUSH
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500 ">


                                                                </td>
                                                            </tr>
                                                            <tr className="bg-red-50">
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    503
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    2000
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500">
                                                                    Fallo la conexion con los servicios de Banco de Mexico

                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500 ">


                                                                </td>
                                                            </tr>
                                                            <tr className="bg-red-50">
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    503
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    2001
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500">
                                                                    Fallo el servicio de Banco de Mexico

                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500 ">


                                                                </td>
                                                            </tr>

                                                            <tr className="bg-red-50">
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    400
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    2002
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500">
                                                                    La cuenta clabe debe estar validada en el portal de Banco de Mexico y pertenecer a la razon social configurada
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500 ">


                                                                </td>
                                                            </tr>
                                                            <tr className="bg-red-50">
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    400
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    2003
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500">
                                                                    El numero celular destinatario no esta enrolado con CoDi
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500 ">


                                                                </td>
                                                            </tr>
                                                            <tr className="bg-red-50">
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    503
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    2004
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500">
                                                                    "No fue posible enviar el mensaje de cobro"
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-red-500 ">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>
                            <section>
                                <div class="py-4 space-y-4">
                                    <h1 class="text-2xl tracking-wide text-gray-700">Payload de los webhooks</h1>
                                    <p class="text-black text-gray-500">El siguiente es un ejemplo del payload que recibiran los webhooks que se configuren en la plataforma cuando una operacion cambie de estado.</p>
                                    <code class="block overflow-x-scroll bg-gray-100 bg-opacity-50 text-gray-700 rounded p-4 whitespace-pre">
                                        {'{'}<br />
                                        {'    "id": "f6839520-4dcc-4ec3-b188-e3940a3f400c",'}<br />
                                        {'    "idCanal": "canal-63830743-45e4-43b0-b089-370226f27a92",'}<br />
                                        {'    "nombreCanal": "ecommerce",'}<br />
                                        {'    "tipoCodi": "MESSAGE",'}<br />
                                        {'    "fechaHora": 1670182261150,'}<br />
                                        {'    "monto": 0.02,'}<br />
                                        {'    "concepto": "Pedido ecommerce",'}<br />
                                        {'    "referencia": 0,'}<br />
                                        {'    "estado": 1,'}<br />
                                        {'    "idCodi": "fc7115e0e42d846be0e4",'}<br />
                                        {'    "claveRastreo": "9149481077",'}<br />
                                        {'    "celular": "5584001331",'}<br />
                                        {'    "fechaHoraProcesamiento": 1670182281000,'}<br />
                                        {'    "metadata": { }'}<br />
                                        {'}'}<br />

                                    </code>
                                </div>
                            </section>


                            <section>
                                <div class="py-4 space-y-4">
                                    <h1 class="text-2xl tracking-wide text-gray-700">Estados de las operaciones</h1>

                                    <div class="flex flex-col">
                                        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                <div class="overflow-hidden border-b border-gray-200">
                                                    <table class="min-w-full divide-y divide-gray-500 text-gray-700">
                                                        <thead class="bg-gray-50">
                                                            <tr>
                                                                <th scope="col"
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                    Estado
                                                                </th>
                                                                <th scope="col"
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                    Nombre
                                                                </th>
                                                                <th scope="col"
                                                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                    Descripcion
                                                                </th>


                                                            </tr>
                                                        </thead>

                                                        <tbody class="bg-white divide-y divide-gray-500">
                                                            <tr >
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    -1
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    Pendiente

                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    No se ha recibido una respuesta por parte del cliente
                                                                </td>
                                                            </tr>
                                                            <tr >
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    1
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    Pagado

                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    Se realizo el cobro de forma exitosa
                                                                </td>
                                                            </tr>
                                                            <tr >
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    2
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    Rechazado

                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    El cliente rechazo el cobro
                                                                </td>
                                                            </tr>
                                                            <tr >
                                                                <td class="px-6 py-4  text-sm font-medium text-gray-900">
                                                                    4
                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    Pospuesto

                                                                </td>
                                                                <td class="px-6 py-4  text-sm text-gray-500">
                                                                    El cliente pospuso el cobro, puede desde su app bancaria retomarlo despues para confirmarlo o rechazarlo
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>

                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}