export const BANCOS = {
    '002':{
       "clave_institucion":40002,
       "nombreCorto":"BANAMEX"
    },
    '012':{
       "clave_institucion":40012,
       "nombreCorto":"BBVA MEXICO"
    },
    '014':{
       "clave_institucion":40014,
       "nombreCorto":"SANTANDER"
    },
    '019': {
        "clave_institucion":37019,
        "nombreCorto":"BANJERCITO"
     },
    '021':{
       "clave_institucion":40021,
       "nombreCorto":"HSBC"
    },
    '030':{
       "clave_institucion":40030,
       "nombreCorto":"BAJIO"
    },
    '036':{
       "clave_institucion":40036,
       "nombreCorto":"INBURSA"
    },
    '042':{
       "clave_institucion":40042,
       "nombreCorto":"MIFEL"
    },
    '044':{
       "clave_institucion":40044,
       "nombreCorto":"SCOTIABANK"
    },
    '058':{
       "clave_institucion":40058,
       "nombreCorto":"BANREGIO"
    },
    '059':{
       "clave_institucion":40059,
       "nombreCorto":"INVEX"
    },
    '060':{
       "clave_institucion":40060,
       "nombreCorto":"BANSI"
    },
    '062':{
       "clave_institucion":40062,
       "nombreCorto":"AFIRME"
    },
    '072':{
       "clave_institucion":40072,
       "nombreCorto":"BANORTE"
    },
    '112':{
       "clave_institucion":40112,
       "nombreCorto":"BMONEX"
    },
    '113':{
       "clave_institucion":40113,
       "nombreCorto":"VE POR MAS"
    },
    '127':{
       "clave_institucion":40127,
       "nombreCorto":"AZTECA"
    },
    '128':{
       "clave_institucion":40128,
       "nombreCorto":"AUTOFIN"
    },
    '130':{
       "clave_institucion":40130,
       "nombreCorto":"COMPARTAMOS"
    },
    '132':{
       "clave_institucion":40132,
       "nombreCorto":"MULTIVA BANCO"
    },
    '133':{
       "clave_institucion":40133,
       "nombreCorto":"ACTINVER"
    },
    '137':{
       "clave_institucion":40137,
       "nombreCorto":"BANCOPPEL"
    },
    '138':{
       "clave_institucion":40138,
       "nombreCorto":"ABC CAPITAL"
    },
    '140':{
       "clave_institucion":40140,
       "nombreCorto":"CONSUBANCO"
    },
    '143':{
       "clave_institucion":40143,
       "nombreCorto":"CIBANCO"
    },
    '145':{
       "clave_institucion":40145,
       "nombreCorto":"BBASE"
    },
    '147':{
       "clave_institucion":40147,
       "nombreCorto":"BANKAOOL"
    },
    '148':{
       "clave_institucion":40148,
       "nombreCorto":"PAGATODO"
    },
    '150':{
       "clave_institucion":40150,
       "nombreCorto":"INMOBILIARIO"
    },
    '156':{
       "clave_institucion":40156,
       "nombreCorto":"SABADELL"
    },
    '634':{
       "clave_institucion":90634,
       "nombreCorto":"FINCOMUN"
    },
    '646':{
       "clave_institucion":90646,
       "nombreCorto":"STP"
    }
}