import React from 'react'
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    CashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    OfficeBuildingIcon,
    SearchIcon,
    DotsVerticalIcon,
    SelectorIcon
} from '@heroicons/react/solid'

import {
    CurrencyDollarIcon,
    BellIcon,
    ClockIcon,
    CogIcon,
    CreditCardIcon,
    DocumentReportIcon,
    HomeIcon,
    MenuAlt1Icon,
    QuestionMarkCircleIcon,
    ScaleIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    XIcon,
    CollectionIcon,
    MenuIcon,
    ViewListIcon,
    QrcodeIcon,
    KeyIcon,
    PlayIcon,
    UsersIcon,
} from '@heroicons/react/outline'

import UserService from "../services/UserService";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Header = (props) => {
    return (
        <>
            <div className="border-b border-gray-200 px-4 sm:flex sm:items-center sm:justify-between lg:px-8">
                <div className="flex-1 min-w-0">

                    <h1 className="text-xl font-semibold leading-6 text-gray-900 sm:truncate">{props.titulo}</h1>
                </div>
                <Menu as="div" className="px-3 relative inline-block text-left">
                    <div>
                        <Menu.Button className="group w-full bg-white rounded-md my-1 px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                            <span className="flex w-full justify-between items-center">
                                <span className="flex min-w-0 items-center justify-between space-x-3">
                                    <img
                                        className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                                        src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                                        alt=""
                                    />
                                    <span className="flex-1 flex flex-col min-w-0">
                                        <span className="text-gray-900 text-sm font-medium truncate">{UserService.getUsername()}</span>
                                        <span className="text-gray-500 text-sm truncate">{UserService.getCompanyName()}</span>
                                    </span>
                                </span>
                                <SelectorIcon
                                    className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                            </span>
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                            <div className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            )}
                                        >
                                            Ver perfil
                                        </a>
                                    )}
                                </Menu.Item>
                            </div>

                            <div className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            onClick={(e) => {e.preventDefault(); window.history.replaceState("", "", "/"); UserService.doLogout(); }}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                            )}
                                        >
                                            Logout
                                        </a>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>

            </div>
        </>
    )
}

export default Header