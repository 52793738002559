/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon } from '@heroicons/react/solid'
import { CheckIcon, ChevronDownIcon, AdjustmentsIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { Menu, Listbox, Transition } from '@headlessui/react'

import { Fragment, useEffect, useState, useRef } from 'react';
import HttpService from '../services/HttpService';

const statusStyles = {
    1: 'bg-green-100 text-green-800',
    '-1': 'bg-yellow-100 text-yellow-800',
    4: 'bg-gray-100 text-gray-800',
    2: 'bg-red-100 text-red-800',
}

const statusNames = {
    1: 'Pagado',
    '-1': 'Pendiente',
    4: 'Pospuesto',
    2: 'Rechazado',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const people = [
    { name: 'Wade Cooper', username: '@wadecooper' },
    { name: 'Arlene Mccoy', username: '@arlenemccoy' },
    { name: 'Devon Webb', username: '@devonwebb' },
    { name: 'Tom Cook', username: '@tomcook' },
    { name: 'Tanya Fox', username: '@tanyafox' },
    { name: 'Hellen Schmidt', username: '@hellenschmidt' },
    { name: 'Caroline Schultz', username: '@carolineschultz' },
    { name: 'Mason Heaney', username: '@masonheaney' },
    { name: 'Claudie Smitham', username: '@claudiesmitham' },
    { name: 'Emil Schaefer', username: '@emilschaefer' },
]

const tipoCodi = [
    { id: '0', nombre: 'Todo' },
    { id: 'QR', nombre: 'QR' },
    { id: 'MESSAGE', nombre: 'MESSAGE' }
]

const estados = [
    { id: '0', nombre: 'Todo' },
    { id: '-1', nombre: 'Pendiente' },
    { id: '1', nombre: 'Pagado' },
    { id: '4', nombre: 'Pospuesto' },
    { id: '2', nombre: 'Rechazado' }
]


export default function Configuration(props) {

    const [empresa, setEmpresa] = useState({})

    const [codigoEmpresa, setCodigoEmpresa] = useState("")
    const [rfc, setRfc] = useState("")
    const [razonSocial, setRazonSocial] = useState("")
    const [aliasCertificado, setAliasCertificado] = useState("")
    const [dv, setDv] = useState("")
    const [cifrado, setCifrado] = useState("")

    const [validateRfcError, setValidateRfcError] = useState("")
    const [validateDvError, setValidateDvError] = useState("")
    const [validateAliasCertificadoError, setValidateAliasCertificadoError] = useState("")
    const [validateRazonSocialError, setValidateRazonSocialError] = useState("")
    const [message, setMessage] = useState("")

    const validForm = useRef(true);


    useEffect(() => {
        props.handleTitulo("Configuracion de la cuenta");
        getEmpresa();
    }, []);

    const isRfc = (rfc) =>
    /^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/i.test(rfc)

    const getEmpresa = () => {
        HttpService.getAxiosClient().get('https://gotpaymx.com/api/codi/empresa')
            .then(res => {
                console.log(res.data)
                setCodigoEmpresa(res.data.codigoEmpresa)
                setRfc(res.data.rfc);
                setRazonSocial(res.data.razonSocial)
                setAliasCertificado(res.data.aliasCertificado)
                setDv(res.data.dv)
                setCifrado(res.data.cifrado)
            })
    }

    const actualizarEmpresa = (e) => {
        e.preventDefault();

        validateForm();
        if (validForm.current) {
            var url = `https://gotpaymx.com/api/codi/empresas/${codigoEmpresa}`;
            HttpService.getAxiosClient().post(url, {
                rfc: rfc,
                razonSocial: razonSocial,
                alias: aliasCertificado,
                dv: dv,
                cifrado: cifrado
            })
                .then(res => {
                    setMessage("¡Se actualizaron los datos correctamente!")
                    console.log(res)
                })
        }
    }

    const validateForm = () => {
        validForm.current = true;

        if(!isRfc(rfc)){
            setValidateRfcError("Debe ingresar un RFC valido")
            validForm.current = false;
        } else {
            setValidateRfcError(null)
        }

        if(!razonSocial?.trim() ){
            setValidateRazonSocialError("Debe ingresar la razon social")
            validForm.current = false;
        } else {
            setValidateRazonSocialError(null)
        }

        if(isNaN(dv) ){
            setValidateDvError("Debe tener un valor numerico")
            validForm.current = false;
        } else {
            setValidateDvError(null)
        }

        if(isNaN(aliasCertificado) || (aliasCertificado?.trim() && aliasCertificado?.length!==20 )){
            setValidateAliasCertificadoError("Debe estar formado por 20 digitos")
            validForm.current = false;
        } else {
            setValidateAliasCertificadoError(null)
        }

    }

    return (
        <div className="mt-6 px-8 max-w-4xl">
            <form onSubmit={actualizarEmpresa}>
                <div className="shadow-lg sm:rounded-md bg-white">
                    <div className="space-y-6 bg-white px-4 py-5 ">
                        <div className=" ">
                            <div>
                                <p className="mt-1 text-sm text-red-500">Informacion importante de la cuenta para operar en la plataforma CoDi</p>
                                <p className="mt-1 text-sm text-red-500">Una vez configurados no debe modificarlos a menos que desee operar con una razon social distinta</p>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                <div className="sm:col-span-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                        RFC
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="rfc"
                                            id="rfc"
                                            value={rfc}
                                            onChange={(e) => setRfc(e.target.value)}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs font-mono"
                                        />
                                        <span className="inline-flex text-xs text-red-700">{validateRfcError}</span>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                        Razon Social <span className="text-xs"> (a la que pertenece la e.firma usada en el registro de Banxico)</span>
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="razonSocial"
                                            id="razonSocial"
                                            value={razonSocial}
                                            onChange={(e) => setRazonSocial(e.target.value)}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs font-mono"
                                        />
                                        <span className="inline-flex text-xs text-red-700">{validateRazonSocialError}</span>
                                    </div>
                                </div>

                                <div className="sm:col-span-4">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Alias de Certificado
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="aliasCertificado"
                                            id="aliasCertificado"
                                            maxLength={20}
                                            value={aliasCertificado}
                                            onChange={(e) => setAliasCertificado(e.target.value)}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs font-mono"
                                        />
                                        <span className="inline-flex text-xs text-red-700">{validateAliasCertificadoError}</span>
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        DV
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="dv"
                                            id="dv"
                                            maxLength={9}
                                            value={dv}
                                            onChange={(e) => setDv(e.target.value)}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs font-mono"
                                        />
                                        <span className="inline-flex text-xs text-red-700">{validateDvError}</span>
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Clave de cifrado
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="cifrado"
                                            id="cifrado"
                                            value={cifrado}
                                            onChange={(e) => setCifrado(e.target.value)}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs font-mono"
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="pt-5">
                            <div className="flex justify-between">
                                <div>
                                    {message}
                                </div>
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
