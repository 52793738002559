import { Fragment, useState } from 'react'
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Sidebar from './Sidebar'
import Header from './Header'
import Landing from './Landing'
import SignUp from './SignUp'
import Documentation from './Documentation'

const routes = [
    {
        path: "/",
        component: Landing,
    },
    {
        path: "/signup",
        component: SignUp
    },
    {
        path: "*",
        component: Landing
    },
    ,
    {
        path: "/documentacion",
        component: Documentation
    }
];

export default function Example() {
    const [titulo, setTitulo] = useState("Inicio")

    return (
        <>
            <div className="min-h-full">

                {/* Main column */}
                <div className="flex flex-col">
                    <main className="flex-1">
                        {/* Page title & actions */}
                            <Routes>
                                {routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={<route.component/>}
                                    />
                                ))}
                            </Routes>
                    </main>
                </div>
            </div>
        </>
    )
}
